<template>
  <div class="mt-10 relative noto">
    <!-- <div class="relative page_img_box">
      <div class="page_img02 relative"></div>
      <div class="f-25 f-22-xs f-16-xxs f-15-xxxs fuchi_shadow absolute_xy inline-block page_titile">
        <span class="noto9 f-black fuchi_shadow"
          >C<span class="f-orange">R</span>EVo </span
        >&nbsp;笑談スタッフの紹介
      </div>
    </div> -->

    <h2 class=" f-20 mb-5">
      メイン・スタッフ
        </h2>

    <div
      ref="card"
      v-for="i in names.length"
      :key="i">
    <v-card
      class="w1100 relative-center mt-10 relative overflow-hidden card opacity0 mb-10"
      :class="{ 'left-in': shows[i - 1] }"
    >
      <v-row>
        <v-col cols="12" xl="5" lg="5" md="5" sm="12">
          <div class="inline-block relative-center card_name">
            <div class="f-16 bold" v-html="names[i - 1]" />
            <div class="kdam mt-1">
              <span class="f-09">Code Name：</span><span class="f-11" v-html="codes[i - 1]" />
            </div>
            <div class="card_title_border inline-block f-11 mt-10">
              保有資格
            </div>
            <div class="mt-4">
              <ul class="text-left inline-block">
                <li v-for="l in licenses[i - 1]" :key="l" v-html="l" />
              </ul>
            </div>
          </div>
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="7" sm="12">
          <div class="bold card_title_border inline-block mt-5 f-11 f-09-xxs f-08-xxxs">
            <span v-if="i == 1 || i == 2 || i == 3"
              >不動産業に携わるようになった&nbsp;きっかけは？</span
            >
             <span v-if="i==4"
              > <span class="noto9 f-black"
              >C<span class="f-orange">R</span>EVo
            </span>スタッフを募集しています</span
            >
          </div>
          <div v-html="texts1[i - 1]" class="text-left line17 mt-4"></div>
          <div class="bold mt-4 card_title_border inline-block f-11 f-10-xxxs">
            <span class="noto9 f-black"
              >C<span class="f-orange">R</span>EVo
            </span>
            <span v-if="i == 1 || i == 2">を立ち上げた&nbsp;きっかけは？</span>
            <span v-if="i == 3">に対する想いは？</span>
            <span v-if="i == 4">で学べること</span>
          </div>
          <div v-html="texts2[i - 1]" class="text-left line17 mt-4"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="5" lg="5" md="5" sm="12">
          <img
            :src="require('@/assets/img/staff' + i + '.jpg')"
            class="staff_img w300 relative-center rounded"
          />
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="7" sm="12">
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="yusei mt-5">
                <v-icon class="f-orange">mdi-flower </v-icon>
                <span class="f-11 px-1 f-10-md f-11-xs">みんなで&nbsp;チワワを描いたヨ</span>
                <v-icon class="f-orange">mdi-flower </v-icon>
              </div>
              <div class="flex-center illust_box">
                <img
                  :src="require('@/assets/img/illust' + i + '.jpg')"
                  class="illust_img relative-center"
                />
              </div>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="yusei mt-5">
                <v-icon class="f-green">fas fa-utensils</v-icon>
                <span class="f-11 px-1">よく食べる&nbsp;ランチ</span>
                <v-icon class="f-green">fas fa-utensils</v-icon>
              </div>
              <div class="mt-5">
                <img
                  :src="require('@/assets/img/food' + i + '.jpg')"
                  class="illust_img relative-center rounded"
                />
                <div class="yusei f-14" v-html="launchs[i - 1]" />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
  </div>
</template>

<script>
import Scroll from "@/mixins/scroll";

export default {
  mixins: [Scroll],
  data() {
    return {
      names: [
        " 吉田&nbsp;&nbsp;暉石",
        "安部&nbsp;&nbsp;貴大",
        "笑談&nbsp;&nbsp;育代"
      ],
      codes: [
        "<span class='f-orange'>K</span>iseki",
        "<span class='f-orange'>T</span>akahiro",
         "<span class='f-orange'>N</span>ewface",
      ],
      licenses: [
        ["宅地建物取引士", "ファイナンシャルプランナー", "暗算6段"],
        ["宅地建物取引士", "ファイナンシャルプランナー", "書道5段"],
        ["宅地建物取引士", "ファイナンシャルプランナー", "笑談スキル","チーム愛"],
      ],
      texts1: [
        "同業者を「ライバル」ではなく「仕事仲間」として接してくれる先輩方が多い、とてもフレンドリーな業界であることを肌で感じ、単純に “素敵だ、働く場所はここだな” と感化されました",
        "飛行機を駐機場へ誘導する係員をご存知ですか？　マーシャラーと言いますが、その仕事に従事しながら興味のあった不動産の勉強を始め、資格取得と同時に転身を遂げました。",
           "弊社のマインドに共感し、仲間に加わってみたいという御方々はエントリーください。年齢・性別・経験云々は、本当に不問。一にも二にも、人間力を重視いたします"
     ],
      texts2: [
        "ふとした縁で貴大君と知り合い、彼の実直なマインドに惹かれて&nbsp;すっかり意気投合。「一緒にコンビを組もう」と、どちらからともなく切り出し「芸人か？ 起業か？」 の二択に絞り込んだ結果、迷いなく起業を決意いたしました",
        "不動産関連の仕事を通じて暉石君と知り合い「お客さん1人1人のニーズを飛行機のようにビタ止めしてみない？」と誘われ、もうその場で即答「Yes!!」　 出会い運の良さに感謝しつつ、最強のチームワークを発揮したいと思います",
       
      "いかに良いモノ・サービスであっても、良い出会いに巡り合わなければ奏功しません。クレボで出会いの大切さを存分に体感していただければ嬉しい限りです。将来、起業を目指す独立心のある方でも大歓迎、夢がかなうよう応援いたします"
      ],
      launchs: ["おにぎり", "オムライス", "例：おかんのキャラ弁"],
      shows: [1, 0,0],
    };
  },

  methods: {
    async scroll01() {
      for (let i = 0; i < this.names.length; i++) {
        if (
          window.innerHeight + this.scroY >
            this.$refs.card[i].getBoundingClientRect().top * 2 &&
          !this.shows[i]
        ) {
          this.shows[i] = 1;
          this.shows.splice();
        }
      }
    },
  },

  watch: {
    scroY() {
      this.scroll01();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/staff.scss";
</style>
